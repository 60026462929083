<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="card">
      <div class="card-body">
        <div>
          <h2 class="text-dark font-medium-3">
            <i class="fa fa-search" /> {{ $t('search') }}
          </h2>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="username">{{ $t('searchType') }}</label>
              <b-form-select
                v-model="search_type"
                :options="search_type_option"
                :placeholder="$t('key-39')"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="username">Keyword</label>
              <input
                id="username"
                v-model="search_val"
                :disabled="!search_type"
                type="text"
                class="form-control"
                :placeholder="$t('searchData')"
              >
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="dateStart">{{ $t('key-40') }}</label>
              <flat-pickr
                v-model="dateStart"
                class="form-control"
                :placeholder="$t('selectStartTime')"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="dateEnd">{{ $t('key-41') }}</label>
              <flat-pickr
                v-model="dateEnd"
                class="form-control"
                :placeholder="$t('selectEndTime')"
              />
            </div>
          </div>

          <div class="col-md-3">
            <button
              class="btn btn-gradient-dark"
              @click="getDeposit()"
            >
              <feather-icon icon="SearchIcon" />
              {{ $t('search') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <b-card no-body>
      <h3 class="ml-2 mt-2">
        {{ $t('item') }}
      </h3>
      <b-table
        small
        striped
        hover
        responsive
        show-empty
        class="position-relative items-center"
        :per-page="perPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #empty="scope">
          <div class="p-2 text-center">
            <img
              src="/box.png"
              alt="empty"
              height="40px"
            > {{ scope.emptyFiltered ? 'No matching records found' :
              $t('key-52') }}
          </div>
        </template>
        <template #cell(slip)="data">
          <img
            height="100"
            :src="data.item.slip"
          >
        </template>
        <template #cell(before_balance)="data">
          {{ data.item.before_balance ? Commas(data.item.before_balance.toFixed(2)) : 0.00 }}
        </template>
        <template #cell(balance)="data">
          <p class="font-weight-bolder text-success mb-0">
            {{ data.item.balance ? Commas(data.item.balance.toFixed(2)) : 0.00 }}
          </p>
        </template>
        <template #cell(amount)="data">
          <p class="font-weight-bolder text-warning mb-0">
            {{ data.item.amount ? Commas(data.item.amount) : 0.00 }}
          </p>
        </template>
        <template #cell(bonus)="data">
          {{ data.item.bonus.toFixed(2) }}
        </template>
        <template #cell(ip)="data">
          <div class="text-nowrap">
            <a
              :href="`http://${data.item.ip}`"
              target="_blank"
            >{{ data.item.ip }}</a>

          </div>
        </template>
        <!-- Column: Detail -->
        <template #cell(detail)="data">
          <div class="text-nowrap">
            <b-button
              v-b-tooltip.hover.v-info
              :title="$t('details')"
              variant="gradient-info"
              size="sm"
              @click="$router.push({ name: 'Deposit-Withdraw', params: { id: data.item.username } })"
            >
              <i class="fas fa-address-card" />
            </b-button>
            <!-- <feather-icon
                :id="`invoice-row-${data.item.id}-preview-icon`"
                icon="FileTextIcon"
                size="16"
                class="mx-1"
                @click="$router.push({ name: 'Deposit-Withdraw', params: { id: data.item.username }})"
              /> -->
            <!-- <b-tooltip
                title="History Deposit & Withdraw"
                :target="`invoice-row-${data.item.username}-preview-icon`"
              /> -->
          </div>
        </template>
        <template #cell(status)="data">
          <div class="text-nowrap">
            <b-badge
              v-if="data.item.status === 'cancel'"
              variant="light-danger"
            >
              {{ data.item.status }}
            </b-badge>
            <b-badge
              v-if="data.item.status === 'waiting'"
              variant="light-warning"
            >
              {{ data.item.status }}
            </b-badge>
            <b-badge
              v-if="data.item.status === 'success'"
              variant="light-success"
            >
              {{ data.item.status }}
            </b-badge>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-badge
              v-if="data.item.status !== 'cancel'"
              variant="light-primary"
              @click="CancelList(data.item)"
            >
              {{ $t('cancelOrder') }}
            </b-badge>

          </div>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="getDeposit()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getDeposit()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
// import vSelect from 'vue-select'
import {
// ValidationProvider,
// ValidationObserver
} from 'vee-validate'
import moment from 'moment-timezone'
import {
  BBadge,
  // BRow,
  // BCol,
  // BIconController,
  // BInputGroupPrepend,
  // BModal,
  BCard, BTable, BFormGroup, BFormSelect, BPagination,
  // BInputGroup,
  // BFormInput,
  BButton, BCardBody, VBToggle,
  // BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required, email } from '@validations'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    flatPickr,
    // BRow,
    BBadge,
    // BFormFile,
    // BCol,
    // vSelect,
    // BInputGroupPrepend,
    // BModal,
    BCard,
    BTable,
    // BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    // BInputGroup,
    // BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    // BIconController,
    // ValidationProvider,
    // ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {

      search_type: null,
      search_val: null,
      emailValue: '',
      time: null,
      time2: null,
      from: null,
      name: '',
      required,
      email,
      show: true,
      depositdata: [],
      member: '',
      amount: '',
      optionsMember: '',
      perPage: 20,
      pageOptions: [10, 15, 20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fromOP: [
        { value: 'scb', text: 'ธนาคารไทยพาณิชย์' },
        { value: 'kbank', text: 'ธนาคารกสิกรไทย' },
        { value: 'truewallet', text: 'truewallet' },
      ],
      timeOp1: [
        { value: '00', text: '00' }, { value: '01', text: '01' },
        { value: '02', text: '02' }, { value: '03', text: '03' },
        { value: '04', text: '04' }, { value: '05', text: '05' },
        { value: '06', text: '06' }, { value: '07', text: '07' },
        { value: '08', text: '08' }, { value: '09', text: '09' },
        { value: '10', text: '10' }, { value: '11', text: '11' },
        { value: '12', text: '12' }, { value: '13', text: '13' },
        { value: '14', text: '14' }, { value: '15', text: '15' },
        { value: '16', text: '16' }, { value: '17', text: '17' },
        { value: '18', text: '18' }, { value: '19', text: '19' },
        { value: '20', text: '20' }, { value: '21', text: '21' },
        { value: '22', text: '22' }, { value: '23', text: '23' },

      ],
      timeOp2: [
        { value: '00', text: '00' }, { value: '01', text: '01' },
        { value: '02', text: '02' }, { value: '03', text: '03' },
        { value: '04', text: '04' }, { value: '05', text: '05' },
        { value: '06', text: '06' }, { value: '07', text: '07' },
        { value: '08', text: '08' }, { value: '09', text: '09' },
        { value: '10', text: '10' }, { value: '11', text: '11' },
        { value: '12', text: '12' }, { value: '13', text: '13' },
        { value: '14', text: '14' }, { value: '15', text: '15' },
        { value: '16', text: '16' }, { value: '17', text: '17' },
        { value: '18', text: '18' }, { value: '19', text: '19' },
        { value: '20', text: '20' }, { value: '21', text: '21' },
        { value: '22', text: '22' }, { value: '23', text: '23' },
        { value: '24', text: '24' }, { value: '25', text: '25' },
        { value: '26', text: '26' }, { value: '27', text: '27' },
        { value: '28', text: '28' }, { value: '29', text: '29' },
        { value: '30', text: '30' }, { value: '31', text: '31' },
        { value: '32', text: '32' }, { value: '33', text: '33' },
        { value: '34', text: '34' }, { value: '35', text: '35' },
        { value: '36', text: '36' }, { value: '37', text: '37' },
        { value: '38', text: '38' }, { value: '39', text: '39' },
        { value: '40', text: '40' }, { value: '41', text: '41' },
        { value: '42', text: '42' }, { value: '43', text: '43' },
        { value: '44', text: '44' }, { value: '45', text: '45' },
        { value: '46', text: '46' }, { value: '47', text: '47' },
        { value: '48', text: '48' }, { value: '49', text: '49' },
        { value: '50', text: '50' }, { value: '51', text: '51' },
        { value: '52', text: '52' }, { value: '53', text: '53' },
        { value: '54', text: '54' }, { value: '55', text: '55' },
        { value: '56', text: '56' }, { value: '57', text: '57' },
        { value: '58', text: '58' }, { value: '59', text: '59' },

      ],

      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      UserData: JSON.parse(localStorage.getItem('userData')),
      show2: false,
      tel: null,
      search: false,
      dateStart: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
      dateEnd: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
      fromOPs: [
        { value: null, text: 'ทั้งหมด' },
        { value: 'scb', text: 'ธนาคารไทยพาณิชย์' },
        { value: 'kbank', text: 'ธนาคารกสิกรไทย' },
        { value: 'truewallet', text: 'truewallet' },
      ],
      search_from: null,
      MaxAmount: 0,
      pic: '',
      selectedFile: null,
    }
  },
  computed: {
    search_type_option() {
      return [
        { value: 1, text: this.$t('amountOfmoney') },
        { value: 2, text: this.$t('key-48') },
        // { value: 2, text: 'เบอร์โทร' },
      ]
    },
    fields() {
      return [
        { key: 'index', label: 'no.' },
        { key: 'status', label: this.$t('status') },
        { key: 'transDate', label: this.$t('key-44') },
        {
          key: 'created_at',
          label: this.$t('time'),
          formatter: value => moment(value)
            .tz('Asia/Bangkok')
            .format('DD/MM/YYYY HH:mm:ss'),
        },
        { key: 'amount', label: this.$t('key-46') },
        { key: 'fix_accno', label: this.$t('key-47') },
        { key: 'name_th', label: this.$t('firstName') },
        { key: 'accno', label: this.$t('key-48') },
        { key: 'bank', label: this.$t('bank') },
      ]
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    this.interval = setInterval(() => {
      // this.getUserslist()
      this.getDeposit()
    }, 30000)
  },
  destroyed() {
    clearInterval(this.interval)
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.getDeposit()
    // this.getAgent()
  },
  methods: {
    clearData() {
      this.member = ''
      this.amount = ''
      this.pic = ''
      this.selectedFile = null
    },
    handleFileUpload(event) {
      // eslint-disable-next-line prefer-destructuring
      this.selectedFile = event.target.files[0]
      if (this.selectedFile) {
        const reader = new FileReader()
        reader.onload = () => {
          const base64Image = reader.result
          // Send the base64Image to the API using an HTTP request
          // Example:
          this.pic = base64Image
        }
        reader.readAsDataURL(this.selectedFile)
      }
    },
    getAgent() {
      this.$http
        .get('/agent/show')
        .then(response => {
          const AgData = response.data
          if (AgData.max_manual_dep_level1 && AgData.max_manual_dep_level2) {
            if (this.UserData.role === 'agadmin') {
              this.MaxAmount = AgData.max_manual_dep_level2
            } else {
              this.MaxAmount = AgData.max_manual_dep_level1
            }
          }
        })
        .catch(error => console.log(error))
    },
    // CancelList(data) {
    //   this.$swal({
    //     title: this.$t('key-291'),
    //     text: `ของ ${data.fullname ? data.fullname : data.username} จำนวน ${data.amount} บาท หรือไม่ ?`,
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonText: 'Yes',
    //     customClass: {
    //       confirmButton: 'btn btn-primary',
    //       cancelButton: 'btn btn-outline-danger ml-1',
    //     },
    //     buttonsStyling: false,
    //   }).then(result => {
    //     if (result.isConfirmed) {
    //       const obj = {
    //         data,
    //       }
    //       this.$http
    //         .post('/deposit/cancel', obj)
    //         // eslint-disable-next-line no-unused-vars
    //         .then(response => {
    //           this.getDeposit()
    //           this.Success(this.$t('key-292'))
    //         })
    //         .catch(error => {
    //           this.SwalError(error.response.data.message)
    //         })
    //     } else {
    //       this.$swal(this.$t('cancel'), this.$t('key-293'), 'error')
    //     }
    //   })
    // },
    getUsersSearch() {
      this.show2 = true
      const obj = {
        tel: this.tel,
      }
      this.$http
        .post('/users/search', obj)
        .then(response => {
          this.member = response.data
          this.search = true
          this.show2 = false
        })
        .catch(error => console.log(error))
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    getUserslist() {
      this.$http
        .get('/users/list')
        .then(response => {
          this.show = false
          this.optionsMember = response.data
        })
        .catch(error => console.log(error))
    },
    getDeposit() {
      // let index = 0
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        search_type: this.search_type,
        search_val: this.search_val,
        search_from: this.search_from,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      }
      this.$http
        .get('/StatementAdmin/statement', { params })
        .then(response => {
          // console.log(response.data)
          this.show = false
          this.items = response.data.data
          this.totalRows = response.data.total
          // this.depositdata.forEach(items => {
          //   this.getAdmin(items.addby, index)
          //   index += 1
          // })
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    getAdmin(addby, index) {
      this.$http
        .get(`/admin/show/${addby}`)
        .then(response => {
          this.show = false
          // console.log(response.data.name)
          this.depositdata[index].addby = response.data.name
        })
        .catch(error => console.log(error))
    },
    // submit() {
    //   this.$refs.simpleRules.validate().then(success => {
    //     if (success) {
    //       if (this.time && this.time2) {
    //         this.show = true
    //         const formData = {
    //           username: this.member.username,
    //           uid: this.member.id,
    //           amount: this.amount,
    //           time: `${this.time}:${this.time2}`,
    //           bank: this.from,
    //           slip: this.pic,
    //         }
    //         this.$http
    //           .post('/deposit/store', formData)
    //           .then(() => {
    //             this.show = false
    //             this.getDeposit()
    //             this.time = null
    //             this.time2 = null
    //             this.member = ''
    //             this.amount = ''
    //             this.from = null
    //             this.clearData()
    //             this.Success(this.$t('key-294'))
    //           })
    //           .catch(error => {
    //             this.show = false
    //             this.time = null
    //             this.time2 = null
    //             this.member = ''
    //             this.amount = ''
    //             this.from = null
    //             this.clearData()
    //             this.SwalError(error.response.data.message)
    //           })
    //       } else {
    //         this.show = false
    //         this.SwalError(this.$t('key-295'))
    //       }
    //     } else {
    //       this.show = false
    //       this.SwalError(this.$t('key-296'))
    //     }
    //   })
    // },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems.data
      this.totalRows = filteredItems.total
      // this.currentPage = 1
    },
    Get_userlist() {
      this.$http
        .get('/user/list')
        .then(response => {
          this.onFiltered(response.data.message)
        })
        .catch(error => console.log(error))
    },
    addData() {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = {}
      this.tel = null
      this.search = false
    },
    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = data
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: `<h3 style="color: #141414">${this.$t('key-297')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
table {
  width: 100%;
  color: #fff !important;
}

thead,
th,
tr {
  color: #fff default;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
  background-color: $product-details-bg;
}

th {
  color: #fff default;
  background-color: #000 !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
