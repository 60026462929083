import { render, staticRenderFns } from "./statement.vue?vue&type=template&id=1bc54ffe&scoped=true&"
import script from "./statement.vue?vue&type=script&lang=js&"
export * from "./statement.vue?vue&type=script&lang=js&"
import style0 from "./statement.vue?vue&type=style&index=0&id=1bc54ffe&prod&scoped=true&lang=css&"
import style1 from "./statement.vue?vue&type=style&index=1&id=1bc54ffe&prod&lang=scss&scoped=true&"
import style2 from "./statement.vue?vue&type=style&index=2&id=1bc54ffe&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bc54ffe",
  null
  
)

export default component.exports